.modal-container {
  @apply absolute z-50 bg-white rounded-md shadow-lg w-full max-w-xl p-6 max-h-max;
}

.title-container {
  @apply flex justify-between mb-4;
}
.title {
  @apply text-lg font-bold;
}
.b-above-to-close {
  @apply text-gray-400 hover:text-gray-800 focus:outline-none;
}
.text-container {
  @apply text-xs text-gray-700 overflow-y-auto p-2 max-h-80;
}
.close-container {
  @apply flex justify-end mt-4;
}
.b-below-to-close {
  @apply px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:bg-gray-300;
}
