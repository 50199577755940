.not-found {
  text-align: center;
  margin-top: 50px;
  font-size: 24px;
}

.sad-icon {
  font-size: 6em;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
