.spinner-container {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

.spinner-text {
  color: #000000;
  margin-left: 8px;
}
