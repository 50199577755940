.cardstyle {
  background: #23a07b;
  position: relative;
  display: flex;
  place-content: center;
  place-items: center;
  overflow: hidden;
  border-radius: 20px;
}

.cardstyle h2 {
  z-index: 1;
  color: white;
  font-size: 2em;
}

.cardstyle::before {
  content: '';
  position: absolute;
  width: 100px;
  background-image: linear-gradient(180deg, rgb(0, 183, 255), rgb(255, 48, 255));
  height: 160%;
  animation: rotBGimg 3s linear infinite;
  transition: all 0.2s linear;
}

@keyframes rotBGimg {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

 .cardstyle::after {
  content: '';
  position: absolute;
  background: #23a07b;
  inset: 5px;
  border-radius: 20px;
}   
 .cardstyle:hover:before {
  background-image: linear-gradient(180deg, rgb(7, 253, 159), rgb(240, 49, 1));
  animation: rotBGimg 3.5s linear infinite;

} 

.cardstyle a {
  position: relative;
  display: block;
  z-index: 2;
}

.cardstyle img {
  position: relative;
  z-index: 2;
}

.card-name {
  position: relative;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  @apply text-center uppercase text-sm  text-black;
}