.contact-space {
  @apply px-6 lg:px-8;
}

.contact-header {
  @apply mx-auto max-w-2xl text-center mb-4;
}

.contact-title {
  @apply text-4xl font-bold tracking-tight text-black sm:text-5xl;
}

.contact-subtitle {
  @apply mt-2 text-lg leading-8 text-black;
}

.form {
  @apply mx-auto mt-16 max-w-xl sm:mt-12;
}

.form-grid-contact {
  @apply grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2;
}

.form-label {
  @apply block text-sm font-semibold leading-6 text-black;
}

.form-input {
  @apply block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-inset ring-gray-300  text-white placeholder-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6;
}

.form-input-space {
  @apply mt-2.5;
}

.form-input-column {
  @apply sm:col-span-2;
}

.phone-label {
  @apply relative form-input-space;
}

.phone-input-group {
  @apply absolute inset-y-0 left-0 flex items-center;
}

.phone-input-select {
  @apply h-full rounded-md border-0 bg-transparent  py-0 pl-4 pr-9 text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm;
}

.phone-input {
  @apply block w-full rounded-md border-0 px-3.5 py-2 pl-20 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6;
}

.switch-group {
  @apply flex gap-x-4 form-input-column;
}

.switch-place {
  @apply flex h-6 items-center;
}

.switch {
  @apply flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600;
}

.switch-label {
  @apply text-sm leading-6 text-black;
}

.switch-label-link {
  @apply font-semibold text-blue-800;
}
