.main-section {
    @apply mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8;
  }
  
  .main-section-grid {
    @apply grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8;
  }
  
  .main-title {
    @apply text-4xl font-bold tracking-tight text-black sm:text-5xl;
  }
  .search-container {
      margin-left: 10px;
      margin-bottom: -10px;
  }