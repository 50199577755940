.about-background {
  @apply hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl;
}

.about-shadows {
  @apply aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#FBCD4F] to-[#6BCDA1] opacity-20;
  clip-path: polygon(
    74.1% 44.1%,
    100% 61.6%,
    97.5% 26.9%,
    85.5% 0.1%,
    80.7% 2%,
    72.5% 32.5%,
    60.2% 62.4%,
    52.4% 68.1%,
    47.5% 58.3%,
    45.2% 34.5%,
    27.5% 76.7%,
    0.1% 64.9%,
    17.9% 100%,
    27.6% 76.8%,
    76.1% 97.7%,
    74.1% 44.1%
  );
}

.about-background2 {
  @apply absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu;
}

.about-section {
  @apply mx-auto max-w-7xl px-6 lg:px-8;
}

.about-description {
  @apply mx-auto max-w-2xl lg:mx-0;
}

.about-title {
  @apply text-4xl font-bold tracking-tight text-black sm:text-6xl;
}

.about-subtitle {
  @apply mt-6 text-lg leading-8 text-black;
}

.about-extras {
  @apply mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none;
}

.about-extras-grid {
  @apply grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-black sm:grid-cols-2 md:flex lg:gap-x-10 justify-center;
}

.about-info {
  @apply mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4;
}

.about-info-text {
  @apply text-base leading-7 text-black;
}

.about-info-value {
  @apply text-2xl font-bold leading-9 tracking-tight text-black;
}

.about-horizontal {
  @apply hero-content flex-col lg:flex-row-reverse max-w-full;
}
