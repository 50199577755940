.hero-section {
  @apply hero min-h-screen;
}

.hero-horizontal {
  @apply hero-content flex-col lg:flex-row;
}

.hero-text {
  @apply text-center text-black max-w-md;
}

.hero-title {
  @apply text-3xl font-bold;
}
