.App {
  text-align: center;
  background-color: white;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
@media (min-width: 1190px) {
  .display-sidebar .flex-sidebar {
    flex: 1 10%;
  }
  .flex-sidebar {
    display: flex;
  }
}

.coffee .paypal {
    font-size: 18px;
}


/* WebKit */
::-webkit-scrollbar {
  width: 12px;
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 6px;
}

/* Firefox */
.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #ccc #fff;
}
.scrollbar::-webkit-scrollbar-track {
  background-color: #fff;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 6px;
}

.sidebar-container {
  @apply w-1/4 p-4;
}

@media (max-width: 1190px) {
  .sidebar-container {
    @apply w-0 p-0;
  }
}
