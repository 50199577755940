.footer-section {
  @apply footer px-4 pt-4 bg-emerald-500 text-black flex justify-around;
  @apply flex-col md:flex-row;
  @apply items-center;
  @apply space-y-4 md:space-y-0 md:space-x-8;
}

.footer-width {
  @apply form-control;
  @apply md:w-80;
}

.footer-input {
  @apply input input-bordered input-group input-group-vertical placeholder-white;
  @apply md:w-full mb-4;
}

.footer-button {
  @apply md:absolute md:top-0 md:right-0;
}

.center-element {
  @apply place-items-center md:place-items-start;
}

.donation-modal-container {
  @apply fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-gray-200 rounded-md shadow-lg max-w-xl p-6;
}

.donation-prompt-modal-content {
  @apply bg-white rounded-lg p-6;
}

.donation-title-container {
  @apply mb-4 text-lg font-semibold;
}

.donation-text-container {
  @apply text-sm text-gray-700 overflow-y-auto max-h-40;
}

.close-container {
  @apply flex justify-end mt-4;
}

.b-below-to-close {
  @apply px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:bg-gray-300;
}


@media (min-width: 768px) {
  .footer-section {
    align-items: start;
  }
}

@media (max-width: 767px) {
  .footer-section {
    align-items: center;
  }
}
