.cookies-modal-container {
  @apply fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-gray-200 rounded-md shadow-lg max-w-xl p-6;
}

.prompt-modal-content {
  @apply bg-white rounded-lg p-6;
}

.cookies-title-container {
  @apply flex items-center justify-center text-center mb-4 text-lg font-semibold;
}

.cookies-text-container {
  @apply text-sm text-gray-700 overflow-y-auto max-h-60;
}

@media (max-width: 640px) {
  .cookies-title-container {
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    overflow-y: auto;
    max-height: 15rem;;
  }
}
