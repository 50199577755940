.carousel-card-wrapper {
    max-width: 60%;
    margin: 0 auto;
    position: relative;
}

.btn-container {
    cursor: pointer;
    padding: 10px;
}

.btn-style {
   @apply btn btn-circle bg-emerald-500 text-white hover:text-gray-500 border-none hover:bg-emerald-500;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}
