.navbar-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #fff; 
}

.nav-container {
  @apply mx-auto max-w-7xl px-2 lg:px-8;
}

.nav-buttons-container {
  @apply relative flex h-14 items-center justify-between;
}

.mobile-menu-button {
  @apply absolute inset-y-0 left-0 flex items-center sm:hidden;
}

.close-mobile-menu {
  @apply inline-flex items-center justify-center rounded-md p-2 text-black hover:bg-gray-700 hover:text-black focus:outline-none focus:ring-2 focus:ring-inset focus:text-black;
}

.close-mobile-menu-size {
  @apply block h-6 w-6;
}

.center-nav-left-button {
  @apply flex flex-1 items-center justify-center sm:items-stretch sm:justify-start;
}

.center-nav-right-button {
  @apply absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0;
}

.company-button {
  @apply flex flex-shrink-0 items-center;
}

.mobile-company-button {
  @apply block h-8 w-auto lg:hidden;
}

.desktop-company-button {
  @apply hidden h-8 w-auto lg:block;
}

.buttons-logo-separation {
  @apply hidden sm:ml-6 sm:block;
}

.nav-buttons {
  @apply rounded-md px-3 py-2 text-sm font-medium;
}

.mobile-nav-buttons {
  @apply block rounded-md px-3 py-2 text-base font-medium;
}

.nav-button-selected {
  @apply bg-gray-900 text-white;
}

.nav-default-button {
  @apply text-black hover:bg-gray-700 hover:text-white;
}

.nav-notif-button {
  @apply rounded-full p-1 text-gray-400;
}

.profile-dropdown {
  @apply flex rounded-full text-sm;
}

.right-button-focus {
  @apply focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800;
}

.profile-menu {
  @apply absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none;
}

.menu-item {
  @apply block px-4 py-2 text-sm text-gray-700;
}
