.header-containter {
  @apply container mx-auto mt-4 mb-4;
}

.header-container2 {
  @apply flex justify-between;
}

.titleTable {
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(0, 0, 0);
  font-weight: bold;
  text-align: center;
}
