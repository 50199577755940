.info-container {
  background-color: #34d399;
  @apply p-4 my-4 grid gap-5 grid-cols-4 rounded-md;
}

.main-section {
  @apply mx-auto max-w-full px-4 py-1 sm:px-6;
}

.left-content {
  @apply rounded-md max-h-min col-span-1;
}

.info-title {
  @apply text-4xl font-bold tracking-tight text-black sm:text-5xl;
}

.info-paragraph {
  @apply mt-2 text-lg leading-8 text-black;
}

.search-input {
  @apply px-1 py-1 mb-2 border rounded-lg text-white placeholder-white;
}

.search-input:focus,
.search-input:hover {
  border-color: yellow;
}

#sidebar {
  @apply md:block hidden font-bold text-black bg-white border rounded-md p-4;
}

#sidebar-item {
  @apply text-gray-600 cursor-pointer hover:text-black p-2;
}

.right-column {
  @apply mt-10 bg-white w-full text-black rounded-md col-span-3;
}

@media (max-width: 767px) {
  .info-container {
    @apply grid grid-cols-1 gap-1;
  }

  .right-column {
    @apply mt-0;
  }
}
