.pagination-position {
  @apply flex flex-col md:flex-row space-x-2 justify-center;
}

.button-style {
  @apply btn-sm px-2 py-1;
}

.pagination-number {
  @apply btn btn-link btn-sm px-2 py-1;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #cccccc;
  border-radius: 4px;
  text-decoration: none;
}

.pagination-number:active,
.pagination-number.active {
  background-color: black; 
  color: white; 
}

@media (max-width: 765px) {
  .button-style,
  .pagination-number {
    margin: 3px;
  }
}

@media (max-width: 765px) {
  .button-style,
  .pagination-number {
    margin: 3px;
  }
}
