 .contry-comparison-title {
   @apply text-4xl font-bold tracking-tight text-black sm:text-5xl;
 }

 .form-grid {
   @apply grid grid-cols-2 place-content-evenly;
   @apply gap-2 md:gap-40;
 }

 @media (max-width: 640px) {
   .form-grid {
     @apply grid grid-cols-1;
   }
 }

 .centered-table {
   @apply mx-auto justify-center;
 }

 .centered-table img {
   display: block;
   margin: 0 auto;
 }

 .custom-tooltip {
  max-width: 40%;
  word-wrap: break-word; 
  white-space: pre-wrap; 
}

 