.table-header {
  @apply my-4 grid grid-cols-3 grid-rows-1;
}

.input-filter {
  @apply col-span-1 justify-self-end;
}

.table-scroll {
  margin: 20px;
  overflow-x: auto;
}

.table-scroll::-webkit-scrollbar {
  width: 10px; 
}

.table-scroll::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 5px; 
}

.table-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}

.table-containter {
  @apply container mx-auto mt-12 mb-12;
}

.table-scroll::-webkit-scrollbar {
  height: 10px; 
}

.table-content {
  @apply p-4 border-t text-black;
}

.input {
  color: white;
}

.table-auto {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  border: 2px solid #ccc;
  border-radius: 10px;
  overflow: hidden; 
}

.table-auto td,
.table-auto th {
  padding: 12px;
}

.table-auto th:first-child {
  border-top-left-radius: 8px;
}

.table-auto th:last-child {
  border-top-right-radius: 8px;
}

.table-auto td:first-child {
  border-bottom-left-radius: 8px;
}

.table-auto td:last-child {
  border-bottom-right-radius: 8px;
}

.table-auto thead {
  color: #fff;
  font-weight: bold;
  @apply bg-emerald-500;
}

.table-auto tbody tr:hover {
  background-color: #ddd;
}

@media (max-width: 640px) {
  .table-header {
    @apply block;
  }
  .input-filter {
    @apply mt-4;
  }
}
