@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input,
textarea, select{
  background-color: #23a07b;
}

.primary-button{
  @apply btn btn-primary bg-emerald-500 hover:bg-amber-300 text-black;
}

/* TAILWIND */
.yellow-bar {
  @apply bg-amber-300 h-2;
}

.main-green-background {
  @apply bg-emerald-500;
}

